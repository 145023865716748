// //------------ with rotation and movement ---------

// import React, { Suspense, useEffect, useState,useRef } from "react";
// import { Canvas, useFrame } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// const Computers = ({ isMobile }) => {
//   const computer = useGLTF("./LOT(144).glb");
//   // const computer = useGLTF("./desktop_pc/scene.gltf");
//   const computerRef = useRef();

//   // Use the useFrame hook to animate the rotation of the computer model
//   useFrame(({ clock }) => {
//     computerRef.current.rotation.y = clock.elapsedTime * 0.1; // Adjust the rotation speed as needed
//   });

//   return (
//     <mesh>
//       <hemisphereLight intensity={0.15} groundColor="black" />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//         object={computer.scene}
//         scale={isMobile ? 0.7 : 0.75}
//         position={isMobile ? [0, -3, -2.2] : [0, -3.25, -1.5]}
//         rotation={[-0.01, -0.2, -0.1]}
//         ref={computerRef}
//       />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 500px)");
//     setIsMobile(mediaQuery.matches);

//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Canvas
//       frameloop="demand"
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [20, 3, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >
//       <OrbitControls
//       autoRotate
//       autoRotateSpeed={10}
//         enableZoom={true}
//         maxPolarAngle={Math.PI}
//         minPolarAngle={0}
//       />
//       <Computers isMobile={isMobile} />
//       <Preload all />
//     </Canvas>
//   );
// };

// export default ComputersCanvas;

//---------------------------- moving with zoom

// import React, { Suspense, useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// // import CanvasLoader from "../Loader";

// const Computers = ({ isMobile }) => {
//   const computer = useGLTF("./desktop_pc/scene.gltf");

//   return (
//     <mesh>
//       <hemisphereLight intensity={0.15} groundColor='black' />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//         object={computer.scene}
//         scale={isMobile ? 0.7 : 0.75}
//         position={isMobile ? [0, -3, -2.2] : [0, -3.25, -1.5]}
//         rotation={[-0.01, -0.2, -0.1]}
//       />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     // Add a listener for changes to the screen size
//     const mediaQuery = window.matchMedia("(max-width: 500px)");

//     // Set the initial value of the `isMobile` state variable
//     setIsMobile(mediaQuery.matches);

//     // Define a callback function to handle changes to the media query
//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     // Add the callback function as a listener for changes to the media query
//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     // Remove the listener when the component is unmounted
//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Canvas
//       frameloop='demand'
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [22, 3, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >

//         <OrbitControls
//         autoRotate
//         autoRotateSpeed={8}
//           enableZoom={true}
//           maxPolarAngle={Math.PI / 2}
//           minPolarAngle={Math.PI / 2}
//         />
//         <Computers isMobile={isMobile} />

//       <Preload all />
//     </Canvas>
//   );
// };

// export default ComputersCanvas;

//--------------- computer normal

// import React, { Suspense, useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// // import CanvasLoader from "../Loader";

// const Computers = ({ isMobile }) => {
//   const computer = useGLTF("./desktop_pc/scene.gltf");

//   return (
//     <mesh>
//       <hemisphereLight intensity={0.15} groundColor='black' />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//         object={computer.scene}
//         scale={isMobile ? 0.7 : 0.75}
//         position={isMobile ? [0, -3, -2.2] : [0, -3.25, -1.5]}
//         rotation={[-0.01, -0.2, -0.1]}
//       />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     // Add a listener for changes to the screen size
//     const mediaQuery = window.matchMedia("(max-width: 500px)");

//     // Set the initial value of the `isMobile` state variable
//     setIsMobile(mediaQuery.matches);

//     // Define a callback function to handle changes to the media query
//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     // Add the callback function as a listener for changes to the media query
//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     // Remove the listener when the component is unmounted
//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Canvas
//       frameloop='demand'
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [22, 3, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >

//         <OrbitControls
//           enableZoom={true}
//           maxPolarAngle={Math.PI / 2}
//           minPolarAngle={Math.PI / 2}
//         />
//         <Computers isMobile={isMobile} />

//       <Preload all />
//     </Canvas>
//   );
// };

// export default ComputersCanvas;

// Earth ------------------------------------------------------------

// import React, { Suspense } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// // import CanvasLoader from "../Loader";

// const Earth = () => {
//   const earth = useGLTF("./planet/scene.gltf");

//   return (
//     <primitive object={earth.scene} scale={2.5} position-y={0} rotation-y={0} />
//   );
// };

// const EarthCanvas = () => {
//   return (
//     <Canvas
//       shadows
//       frameloop='demand'
//       dpr={[1, 2]}
//       gl={{ preserveDrawingBuffer: true }}
//       camera={{
//         fov: 45,
//         near: 0.1,
//         far: 200,
//         position: [-4, 3, 6],
//       }}
//     >
//       {/* <Suspense fallback={<CanvasLoader />}> */}
//         <OrbitControls
//           autoRotate
//           autoRotateSpeed={15}
//           enableZoom={false}
//           maxPolarAngle={Math.PI / 2}
//           minPolarAngle={Math.PI / 2}
//         />
//         <Earth />

//         <Preload all />
//       {/* </Suspense> */}
//     </Canvas>
//   );
// };

// export default EarthCanvas;

// 31 jan

// import React, { Suspense, useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// // import CanvasLoader from "../Loader";

// const Computers = ({ isMobile }) => {
//   // const computer = useGLTF("./desktop_pc/scene.gltf");
//   const computer = useGLTF("./LOT(144).glb"); // wood table working
//   // const computer = useGLTF("./Lot 32 glb.glb");
//   return (
//     <mesh >
//       <hemisphereLight intensity={0.15} groundColor='black' />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//   object={computer.scene}
//   scale={true ? 0.7 : 0.75}
//   position={true ? [0, -3, -2.2] : [0, -3.25, -1.5]}
//   // rotation={[-Math.PI / 2, 0, 0]} // Adjust the rotation here
// />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     // Add a listener for changes to the screen size
//     const mediaQuery = window.matchMedia("(max-width: 500px)");

//     // Set the initial value of the `isMobile` state variable
//     setIsMobile(mediaQuery.matches);

//     // Define a callback function to handle changes to the media query
//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     // Add the callback function as a listener for changes to the media query
//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     // Remove the listener when the component is unmounted
//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <>

//     <Canvas
//       frameloop='demand'
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [40, 2, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >

//         <OrbitControls
//         autoRotate
//            autoRotateSpeed={10}
//           enableZoom={false}
//           maxPolarAngle={Math.PI / 2}
//           minPolarAngle={Math.PI / 2}
//         />
//         <Computers isMobile={isMobile} />

//       <Preload all />
//     </Canvas>

//       </>
//   );
// };

// export default ComputersCanvas;

// import React, { useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// const Computers = ({ isMobile }) => {
//   const computer = useGLTF("./LOT(144).glb");
//   // const computer = useGLTF("./desktop_pc/scene.gltf");
//   // const computer = useGLTF("./Lot 32 glb.glb");

//   return (
//     <mesh>
//       <hemisphereLight intensity={0.15} groundColor="black" />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//         object={computer.scene}
//         scale={isMobile ? 0.7 : 0.75}
//         position={isMobile ? [0, -3, -2.2] : [0, -3.25, -1.5]}
//         rotation={[0, Math.PI / 2, 0]} // Adjust the rotation here
//       />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 500px)");

//     setIsMobile(mediaQuery.matches);

//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Canvas
//       frameloop="demand"
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [30, 2, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >
//       <OrbitControls
//         autoRotate
//         autoRotateSpeed={10}
//         enableZoom={false}
//         maxPolarAngle={Math.PI / 2}
//         minPolarAngle={Math.PI / 2}
//       />
//       <Computers isMobile={isMobile} />
//       <Preload all />
//     </Canvas>
//   );
// };

// export default ComputersCanvas;

// center wise moving properly

// import React, { useEffect, useState } from "react";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

// const Computers = ({ isMobile }) => {
//   // const computer = useGLTF("./LOT(144).glb");
//    const computer = useGLTF("./Lot 32 glb.glb");
//     // const computer = useGLTF("./LOT(344).glb");

//   return (
//     <mesh>
//       <hemisphereLight intensity={0.15} groundColor="black" />
//       <spotLight
//         position={[-20, 50, 10]}
//         angle={0.12}
//         penumbra={1}
//         intensity={1}
//         castShadow
//         shadow-mapSize={1024}
//       />
//       <pointLight intensity={1} />
//       <primitive
//         object={computer.scene}
//         scale={isMobile ? 0.7 : 0.75}
//         position={[0, -3.25, 0]} // Adjust the position to be centered
//         rotation={[0, Math.PI / 2, 0]}
//       />
//     </mesh>
//   );
// };

// const ComputersCanvas = () => {
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 500px)");

//     setIsMobile(mediaQuery.matches);

//     const handleMediaQueryChange = (event) => {
//       setIsMobile(event.matches);
//     };

//     mediaQuery.addEventListener("change", handleMediaQueryChange);

//     return () => {
//       mediaQuery.removeEventListener("change", handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Canvas
//       frameloop="demand"
//       shadows
//       dpr={[1, 2]}
//       camera={{ position: [30, 2, 5], fov: 25 }}
//       gl={{ preserveDrawingBuffer: true }}
//     >
//       <OrbitControls
//         autoRotate
//         autoRotateSpeed={10}
//         enableZoom={false}
//         maxPolarAngle={Math.PI / 2}
//         minPolarAngle={Math.PI / 2}
//       />
//       <Computers isMobile={isMobile} />
//       <Preload all />
//     </Canvas>
//   );
// };

// export default ComputersCanvas;

import React, { useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";

const Computers = ({ isMobile }) => {
  // const computer = useGLTF("./LOT(144).glb");
  //  const computer = useGLTF("./Lot 32 glb.glb");
const computer = useGLTF("./light-house.gltf");


// const computer = useGLTF("./lot79.glb");


// Recline_Sofa
  // const computer = useGLTF("./LOT(344).glb");

  return (
    <mesh>
      {/* Ambient light for overall illumination */}
      <hemisphereLight
        intensity={0.5}
        skyColor="#ffffff"
        groundColor="#444444"
      />

      <spotLight
        position={[-20, 50, 10]}
        angle={0.12}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={1024}
      />

      <pointLight intensity={1} />
      <primitive
        object={computer.scene}
        scale={true ? 0.7 : 0.75}
        position={[0, -3.25, 0]} // Adjust the position to be centered
        rotation={[0, Math.PI / 2, 0]}
      />
    </mesh>
  );
};

const ComputersCanvas11 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <Canvas
      frameloop="demand"
      shadows
      dpr={[1, 2]}
      camera={{ position: [30, 2, 5], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
      
    >
      {/* Orbit controls for interaction */}
      <OrbitControls
        autoRotate
        autoRotateSpeed={10}
        enableZoom={false}
        maxPolarAngle={Math.PI / 2} //if comment this line and below its showuld be rotabtable for every direction
        minPolarAngle={Math.PI / 2}
      />

      {/* Render the Computers component with the 3D model and lights */}
      <Computers isMobile={isMobile} />

      {/* Preload assets */}
      <Preload all />
    </Canvas>
  );
};

export default ComputersCanvas11;
