// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
// import ComputersCanvas from "./Computers";

// // function Model(props) {
// //   const computer = useGLTF("./desktop_pc/scene.gltf");
// //   return <primitive object={scene} {...props} />
// // }

// function App() {
  
//   return (
//     <>
//         <header style={headerStyle}>
//       <img src="https://media.licdn.com/dms/image/D4D0BAQHWKyZ35YDXIA/company-logo_200_200/0/1687160587434?e=1714003200&v=beta&t=w06IxFHQuCFG8E0heF2hAI0FDdZMerIhGSV362GlIFo" alt="Logo" style={logoStyle} />
//       {/* Add other header content here */}
//     </header>

    
//     <div style = {{height:"100vh",width:"100%",alignItems:"center",background:"#171717",textAlign:"center",marginTop:"-99px"}}>
//     <ComputersCanvas/>
//     {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//       <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpSVLl8W16AO4iqoNb48o1gCfiuSszibQSUvSaKjHxa--s0LO4a3ZFV6SWnzBkxJR1ZEw&usqp=CAU" alt="Centered Image" />
//     </div> */}
//     </div>
//     </>

//   );
// }
// const headerStyle = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   padding: '10px',
//   background:"#171717",
//   color: '#fff',

// };

// const logoStyle = {
//   width: '250px', // Set the width of the logo as needed
//   height: '100px', // Set the height of the logo as needed
//   margin: '20px',
  
// };

// export default App;







import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Preload, useGLTF } from "@react-three/drei";
import ComputersCanvas from "./Computers";
import ComputersCanvas2 from "./Computers2";
import ComputersCanvas3 from "./Computers3";
import ComputersCanvas4 from "./Computers4";
import ComputersCanvas5 from "./Computers5";
import ComputersCanvas6 from "./Computers6";
import ComputersCanvas7 from "./Computers7";
import ComputersCanvas8 from "./Computers8";
import ComputersCanvas9 from "./Computers9";
import ComputersCanvas10 from "./Computers10";
import ComputersCanvas11 from "./Computers11";
function App() {
  return (
    <Router>
      <div>
        <header style={headerStyle}>
          <img src="../white.png" alt="Logo" style={logoStyle} />
          {/* Add other header content here */}
        </header>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/furniture-home-appliance-a144ad69-1cd4-43dc-6d1a-ade-3df13d00e07" component={HomePage}/> 
          <Route path="/rosewood-sideboard-a144ad99-1cf4-49dc-9d1a-3df138d00e77" component={HomePage2}/> 
          <Route path="/rustic-table-a144ad99-1cf4-49dc-9d1a-3df138d00e907h" component={HomePage3}/> 
          <Route path="/white-sofa-a144ad99-1cf4-49dc-9d1a-3df138d00e907h" component={HomePage4}/> 
          <Route path="/wooden-table-frame-a144ad99-1cf4-49dc-9d1a-3df138d00e907h" component={HomePage5}/> 
          <Route path="/black-sofa-a144ad99-1cf4-49dca144ad99-1cf4-47h" component={HomePage6}/> 
          <Route path="/white-sofa-40f6af66425aee46&rlz=1C5CHFA-a144ad99-1cf4-49dca144ad99-1cf4-47h" component={HomePage7}/> 
          <Route path="/white-sofa-2-rstfa40f6af66425aee46&rlz=1C5CHFA-a144ad99-1cf4-49dca144ad99-1cf4-47h" component={HomePage8}/> 
          <Route path="/white-legs8cpf3v7-376-tfa40f6af66425aee46&rlz=1C5CHFA-a144ad99-1cf4-49dca144ad99-1cf4-47h" component={HomePage9}/>
          <Route path="/red-chair-86cujdtzg-8cpf3v7-376-tfa40f6af66425aee46&rlz=1C5CHFA-a144ad99-1cf4-49dca144ad99-1cf4-47h" component={HomePage10}/>
          <Route path="/r-86cujdtzg-8cpf3v7-376-tfa40f6af66425aee46&rlz=1C5CHFA-a144ad9989-1cd" component={HomePage11}/>

    
          {/* Add more routes as needed */}
        </Switch>
      </div>
    </Router>
  );
}

const HomePage = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-260px", right: "10px" }}>
      <ComputersCanvas />
    </div>
  );
};
const HomePage2 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-260px", right: "10px" }}>
      <ComputersCanvas2 />
    </div>
  );
};
const HomePage3 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-260px", right: "10px" }}>
      <ComputersCanvas3 />
    </div>
  );
};
const HomePage4 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-230px", right: "10px" }}>
      <ComputersCanvas4 />
    </div>
  );
};
const HomePage5 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-230px", right: "10px" }}>
      <ComputersCanvas5 />
    </div>
  );
};


const HomePage6 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-240px", right: "10px" }}>
     <ComputersCanvas6 />
    </div>
  );
};

const HomePage7 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-280px", right: "10px" }}>
     <ComputersCanvas7 />
    </div>
  );
};
const HomePage8 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-280px", right: "10px" }}>
     <ComputersCanvas8 />
    </div>
  );
};

const HomePage9 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-280px", right: "10px" }}>
     <ComputersCanvas9 />
    </div>
  );
};
const HomePage11 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-280px", right: "10px" }}>
     <ComputersCanvas11 />
    </div>
   
  );
};
const HomePage10 = () => {
  return (
    <div style={{ height: "100vh", width: "100%", alignItems: "center", textAlign: "center", position: "relative", top: "-280px", right: "10px" }}>
     <ComputersCanvas11 />
    </div>
   
  );
};
const AboutPage = () => {
  return <h2>About Page</h2>;
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  background: "#1D062F",
  color: "#fff",
};

const logoStyle = {
  width: "220px", // Set the width of the logo as needed
  height: "50px", // Set the height of the logo as needed
  margin: "30px",
};

export default App;
